declare var Swal;
declare var bootstrap;

//
const base_url = "https://test-api.staiship.com";
const stepOnefields = [
  "firstName",
  "lastName",
  "name",
  "phoneNumber",
  "companyName",
  "maroofLink",
  "storeLink",
  "instagramLink",
];
const stepTowfields = ["email", "password"];
const RegisterTraderFrom = document.getElementById(
  "RegisterTraderFrom"
) as HTMLFormElement;
const FormTrackShipment = document.getElementById(
  "FormTrackShipment"
) as HTMLFormElement;
const ShipmentAlertText = document.getElementById(
  "ShipmentAlertText"
) as HTMLHeadingElement;
const shipmentWrapDetails = document.getElementById(
  "shipmentWrapDetails"
) as HTMLDivElement;

enum SHIPMENT_TYPE {
  AW_PICKUP = "AWAITING_PICKUP", // Assigned to a driver, awaiting pickup.
  AW_PICKUP_AS = "AWAITING_PICKUP_ASSIGNMENT", // Assigned to a driver, awaiting pickup.
  PICKED = "PICKED_UP", // The shipment has been picked up by the driver.
  PICKUP_RETURN = "PICKUP_DRIVER_RETURN", // Assigned to pickup driver for return to merchant.
  AS_DELIVERY = "ASSIGNED_DELIVERY", // Assigned to a delivery driver.
  OUT = "OUT_FOR_DELIVERY", // The shipment is out for delivery.
  SORTING_C = "SORTING_CENTER",
  WAREHOUSE_RETURN = "DELIVERY_WAREHOUSE_RETURN",
  WAREHOUSE_RETURN_WAITING = "DELIVERY_WAREHOUSE_RETURN_WAITING",
  RETUREND_TO_M = "RETURNED_TO_MERCHANT",
  ARAMEX_OUT = "ARAMEX_OUT_FOR_DELIVERY", // The shipment is out for delivery.
  DELIVERY_RETURN = "DELIVERY_DRIVER_RETURN", // The delivery driver intends to return the shipment.
  DONE = "DELIVERED", // The shipment has been delivered.
}

/**
 *
 * @param type
 * @returns
 */
function shipmentStatusTimeLine(
  type: SHIPMENT_TYPE,
  shipment: { [key: string]: any }
): string | null {
  // ES6 Destruction
  const {
    merchant: { name: merchantName },
    deliveryAddress: { name: deliveryAddressName },
  } = shipment || {};

  switch (type) {
    case SHIPMENT_TYPE.AW_PICKUP_AS:
      return `قيد المراجعة من: ${merchantName}`;
    case SHIPMENT_TYPE.PICKED:
      return `تم استلام الشحنة بنجاح من: ${merchantName}`;
    case SHIPMENT_TYPE.SORTING_C:
      return `وصلت الشحنة محطة الفرز`;
    case SHIPMENT_TYPE.OUT:
    case SHIPMENT_TYPE.ARAMEX_OUT:
      return `جاري تسليم الشحنة للمستلم`;
    case SHIPMENT_TYPE.WAREHOUSE_RETURN:
      return `وصلت الشحنة محطة الفرز- شحنة معادة`;
    case SHIPMENT_TYPE.RETUREND_TO_M:
      return `تم استلام الشحنة بنجاح من : ${merchantName}`;
    case SHIPMENT_TYPE.DONE:
      return `تم التسليم إلى: ${deliveryAddressName}`;
    case SHIPMENT_TYPE.WAREHOUSE_RETURN_WAITING:
      return `جاري إعادة الشحنة إلى محطة الفرز`;
    default:
      return null;
  }
}

/**
 *
 * @param type
 * @returns
 */
function shipmentStatusText(type: SHIPMENT_TYPE): string | null {
  switch (type) {
    case SHIPMENT_TYPE.AW_PICKUP_AS:
      return `قيد المراجعة`;
    case SHIPMENT_TYPE.PICKED:
      return `تم استلام من التاجر`;
    case SHIPMENT_TYPE.SORTING_C:
      return `محطة التوزيع`;
    case SHIPMENT_TYPE.OUT:
    case SHIPMENT_TYPE.ARAMEX_OUT:
      return `جاري التسليم`;
    case SHIPMENT_TYPE.WAREHOUSE_RETURN:
      return `تم الارجاع للفرز`;
    case SHIPMENT_TYPE.RETUREND_TO_M:
      return `تم الارجاع`;
    case SHIPMENT_TYPE.DONE:
      return `تم التسليم`;
    default:
      return null;
  }
}

/**
 *
 * @param event
 */
function redirectToTrackShipment(event: SubmitEvent): void {
  /**
   * preventDefault
   */
  event.preventDefault();

  /**
   * init
   */
  const btn = event.submitter as HTMLButtonElement;

  // ES6 Destruction
  const form = event.currentTarget as HTMLFormElement;
  /**
   * fields
   */
  const shipmentId = form.elements.namedItem("shipmentId") as HTMLInputElement;

  if (shipmentId.checkValidity()) {
    window.location.href = `/shipment-tracking.html?shipmentId=${shipmentId.value}`;
  }
}

/**
 *
 * @param id shipment id
 */
async function trackShipment(event: SubmitEvent) {
  /**
   * preventDefault
   */
  event.preventDefault();

  /**
   * init
   */
  const btn = event.submitter as HTMLButtonElement;

  // form
  const form = event.currentTarget as HTMLFormElement;
  /**
   * fields
   */
  const shipmentId = form.elements.namedItem("shipmentId") as HTMLInputElement;

  if (shipmentId.checkValidity() && isLoading == false) {
    //
    try {
      /**
       * start loading
       */
      isLoading = true;
      btn.disabled = true;
      btn.textContent = "بحث...";

      /**
       *
       */
      ShipmentAlertText.classList.remove("d-none");
      shipmentWrapDetails.classList.add("d-none");
      ShipmentAlertText.firstElementChild?.classList.add("d-none");
      ShipmentAlertText.lastElementChild?.classList.remove("d-none");

      /**
       * api call
       */
      const ft = await fetch(`${base_url}/client/track/${shipmentId.value}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      /**
       * convert to json
       */
      const json = await ft.json();
      // ES6 Destruction
      const { shipment, history } = json as { shipment: any; history: any[] };

      /**
       * throw Error
       */
      if (!ft.ok || shipment == null) throw ft;

      /**
       * show shipment wrap Details
       */
      ShipmentAlertText.classList.add("d-none");
      shipmentWrapDetails.classList.remove("d-none");

      /**
       *
       */
      const ulDetails = shipmentWrapDetails.querySelector(
        ".ul_details"
      ) as HTMLUListElement;
      const ulTimeLine = shipmentWrapDetails.querySelector(
        ".ul_time_line"
      ) as HTMLUListElement;

      const {
        id,
        inRiyadh,
        description,
        deliveryAddress: { city, country },
        createdAt,
      } = shipment;

      const formate = new Date(createdAt).toLocaleDateString("ar-EN", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        year: "numeric",
        minute: "2-digit",
      });

      ulDetails.innerHTML = `
                <li class="font_14 font_font_400 line_h_24 margin_bottom_5">
                    <span class="d-inline-block margin_end">رقم الطلب :</span>
                    <span class="d-inline-block font_600">#${id}</span>
                </li>
                <li class="font_14 font_font_400 line_h_24 margin_bottom_5">
                    <span class="d-inline-block margin_end">تاريخ الطلب :</span>
                    <span class="d-inline-block font_300">${formate}</span>
                </li>
                <li
                    class="font_14 font_font_400 line_h_24 margin_bottom_5 d-flex">
                    <span class="d-inline-block margin_end">حالة الطلب :</span>
                    <div class="app_badge default color_green d-inline-block">
                        ${shipmentStatusText(history[0].status)}
                    </div>
                </li>
                <li class="font_14 font_font_400 line_h_24">
                    <span class="d-inline-block margin_end">وصف الشحنة:</span>
                    <span class="d-inline-block font_300">${description}</span>
                </li>
            `;

      ulTimeLine.innerHTML = history
        .map((h) => {
          const d = new Date(h.timestamp)
            .toLocaleDateString("ar-EN", {
              month: "long",
              day: "numeric",
              hour: "numeric",
              year: "numeric",
              minute: "2-digit",
            })
            .split(" ");

          if (shipmentStatusTimeLine(h.status, shipment)) {
            return `
                        <li class="padding_bottom_12 active font_12 font_300 color_text d-flex align-items-center">
                            <div class="wrap_date">
                                <span class="d-block">${d[0]} ${d[1]} ${d[2]}</span>
                                <span class="d-block">${d[4]} ${d[5]}</span>
                            </div>
                            <div class="wrap_progress d-flex align-items-center justify-content-center">
                                <span class="progress_dot"></span>
                            </div>
                            <div class="wrap_status line_h_24">
                                <span class="d-block font_400">
                                    ${shipmentStatusTimeLine(h.status, shipment)}
                                </span>
                                <span class="d-block font_300 sub_color_md_text">
                                    ${getLocation(h.status, shipment)}
                                </span>
                            </div>
                        </li>
                    `;
          }
        })
        .join(" ");

      /**
       * stop loading
       */
      isLoading = false;
      btn.disabled = false;
      btn.textContent = "تتبع";
    } catch (err) {
      /**
       * stop loading
       */
      isLoading = false;
      btn.disabled = false;
      btn.textContent = "تتبع";

      /**
       * show not found shipment alert
       */
      ShipmentAlertText.classList.remove("d-none");
      ShipmentAlertText.lastElementChild?.classList.add("d-none");
      ShipmentAlertText.firstElementChild?.classList.remove("d-none");

      /**
       * hide shipment wrap Details
       */
      shipmentWrapDetails.classList.add("d-none");
    }
  }
}

/**
 *
 * @param event
 */
function asyncShipmentId(event: InputEvent): void {
  /**
   * element will show shipmentId
   */
  const shipmentElement = document.getElementById("ShipmentId");

  /**
   * throw Error
   */
  if (shipmentElement == null)
    throw new Error(`shipmentId is ${shipmentElement}`);

  /**
   * target input field
   */
  const input = event.target as HTMLInputElement;

  /**
   * filter text to get number only
   */
  const numberOnly = input.value.replace(/[^0-9]/g, "");

  /**
   * set input to remove trash chars
   */
  input.value = numberOnly;

  /**
   * async same Id
   */
  shipmentElement.textContent = `#${numberOnly.trim()}`;
}

/**
 *
 * @param event
 * @description submit from contact us
 */
async function submitContactUs(event: SubmitEvent) {
  /**
   *
   */
  event.preventDefault();

  /**
   * init
   */
  const btn = event.submitter as HTMLButtonElement;
  const form = event.currentTarget as HTMLFormElement;

  /**
   * fields
   */
  const name = (form.elements.namedItem("name") as HTMLInputElement).value;
  const email = (form.elements.namedItem("email") as HTMLInputElement).value;
  const message = (form.elements.namedItem("message") as HTMLInputElement)
    .value;
  const reason = (form.elements.namedItem("reason") as HTMLInputElement).value;
  const title = (form.elements.namedItem("title") as HTMLInputElement).value;

  // Create an object with the form data
  var formData = {
    name,
    email,
    message,
    reason,
    title,
  };

  //
  try {
    /**
     *
     */
    btn.disabled = true;
    btn.textContent = "جاري الارسال";

    /**
     * api call
     */
    const ft = await fetch(`${base_url}/client/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    /**
     * throw Error
     */
    if (ft.status !== 201) throw new Error("حدث خطأ ما");

    /**
     * rest form
     */
    form.reset();

    Swal.fire({
      icon: "success",
      confirmButtonText: "موافق",
      title: "تم ارسال طلبك بنجاح",
    });

    /**
     *
     */
    btn.disabled = false;
    btn.textContent = "ارسال";
  } catch (err) {
    // ES6 Destruction
    const { message, error } = err;

    Swal.fire({
      text: message,
      title: error,
      icon: "error",
      confirmButtonText: "موافق",
    });

    /**
     *
     */
    btn.disabled = false;
    btn.textContent = "ارسال";
  }
}

/**
 *
 */
function stepOneIsValid(): void {
  /**
   * filter fields to get enabled input
   */
  const arr = stepOnefields.filter((field) => {
    const input = RegisterTraderFrom.elements[field] as HTMLInputElement;

    return input.disabled == false;
  });

  /**
   * then check in validity
   */
  const isValid = arr.every((field) => {
    const input = RegisterTraderFrom.elements[field] as HTMLInputElement;

    return input.validity.valid;
  });

  if (isValid) nextStep("stepTow");
  else RegisterTraderFrom.reportValidity();
}

/**
 *
 * @param event
 */
async function submitRegisterTrader(event: SubmitEvent) {
  /**
   *
   */
  event.preventDefault();

  /**
   * init
   */
  const formData = { employeeName: "" };
  const btn = event.submitter as HTMLButtonElement;
  const form = event.currentTarget as HTMLFormElement;

  if (form.checkValidity() && isLoading == false) {
    const defaultUi = btn.innerHTML;

    /**
     * start loading
     */
    isLoading = true;
    btn.disabled = true;
    btn.textContent = "جاري التحقق";

    /**
     *
     */
    stepOnefields.concat(stepTowfields).forEach((field) => {
      const input = form.elements.namedItem(field) as HTMLInputElement;

      if (!input.disabled) {
        if (field == "firstName") {
          formData["employeeName"] += input.value;
        }
        if (field == "lastName") {
          formData["employeeName"] += " " + input.value;
        } else formData[field] = input.value;
        if (field === "phoneNumber") {
          formData["phoneNumber"] = "+966" + formData["phoneNumber"];
        }
      }
    });

    //
    try {
      /**
       * api call
       */
      const ft = await fetch(`${base_url}/auth/merchant/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      /**
       * convert to json
       */
      const json = await ft.json();

      /**
       * throw Error
       */
      if (!ft.ok) throw json;

      /**
       * rest form
       */
      form.reset();

      /**
       *
       */
      const myModal = new bootstrap.Modal("#staticBackdrop", {
        keyboard: false,
      });
      myModal.show();

      /**
       * stop loading
       */
      isLoading = false;
      btn.disabled = false;
      btn.innerHTML = defaultUi;
    } catch (err) {
      // ES6 Destruction
      const { message, error } = err;

      Swal.fire({
        text: message,
        title: error,
        icon: "error",
        confirmButtonText: "موافق",
      });

      /**
       * stop loading
       */
      isLoading = false;
      btn.disabled = false;
      btn.innerHTML = defaultUi;
    }
  } else if (isLoading == false) form.reportValidity();
}

/**
 *
 * @param event input event
 * @param errorElementId element will show current error
 */
function validationInput(event: Event, errorElementId?: string) {
  /**
   * stop default behavior
   */
  event.preventDefault();

  /**
   * init
   */
  const input = event.target as HTMLInputElement;
  const container = input.parentElement?.parentElement;
  const errorMsg = document.getElementById(errorElementId || "");

  /**
   *
   */
  if (errorMsg == null) throw new Error("errorElementId is undefined");
  if (container == null) throw new Error("container is undefined");

  // ES6 Destruction
  const { required, minLength, maxLength, pattern } = input;
  const { valueMissing, valid, tooShort, tooLong, patternMismatch } =
    input.validity;

  if (valid == false) {
    //
    container.classList.add("in_valid");
    container.classList.remove("is_valid");

    //
    errorMsg.classList.remove("d-none");

    //
    if (valueMissing && required) {
      errorMsg.textContent = "من فضلك ادخل هذا الحقل";
    } else if (patternMismatch) {
      errorMsg.textContent = "صيفة الادخال غير صحيحة";
      if (input.hasAttribute("data-pattern-replace")) {
        input.value = input.value.replace(
          new RegExp(input.getAttribute("data-pattern-replace") as string, "g"),
          ""
        );
      }
    } else if (tooShort) {
      errorMsg.textContent = `اقل عدد من حروف هو ${minLength}`;
    } else if (tooLong) {
      errorMsg.textContent = `اكثر عدد من حروف هو ${maxLength}`;
    }
  } else {
    container.classList.add("is_valid");
    container.classList.remove("in_valid");
    if (errorMsg.textContent != "") errorMsg.textContent = "";
    if (!errorMsg.classList.contains("d-none"))
      errorMsg.classList.add("d-none");
  }
}

/**
 *
 * @param check radio check Yes or no
 * @param questionId container for input field
 */
function radioQuestionToggle(check: boolean, questionId: string): void {
  if (questionId == null) throw new Error(`questionId arg is ${questionId}`);

  /**
   * get element
   */
  const q = document.getElementById(questionId);
  const input = q?.querySelector(".input_field input") as HTMLInputElement;

  /**
   * check if elementId is Available
   */
  if (q == null) throw new Error(`Element is ${questionId}`);
  if (input == null) throw new Error(`input is ${input}`);

  /**
   * show input or hide
   */
  if (check) {
    input.required = true;
    input.disabled = false;
    q.classList.remove("d-none");
  } else {
    input.required = false;
    input.disabled = true;
    q.classList.add("d-none");
  }
}

function getLocation(status: SHIPMENT_TYPE, shipment: any) {
  if (status === SHIPMENT_TYPE.DONE) {
    return `${shipment.deliveryAddress.city}, ${shipment.deliveryAddress.country}`;
  } else {
    return `${shipment.pickupAddress.city}, ${shipment.pickupAddress.country}`;
  }
}
